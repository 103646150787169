<template>

  <div class="w-full flex items-center justify-center px-4" style="height: calc(100vh - 60px)">
    <div class="rounded-lg border bg-card text-card-foreground shadow-sm mx-auto w-full max-w-[360px]">
      <div class="flex flex-col gap-y-1.5 p-4 lg:p-6 lg:pb-0">
        <h1 class="font-semibold tracking-tight text-xl md:text-3xl">Login</h1>
      </div>
      <div class="p-4 lg:p-6 pt-0">
        <form 
        v-if="!forgot"
        @submit.prevent="handleStandardSignin" 
        class="grid gap-4"
        >
          <div class="grid gap-2">
            <!-- <label 
            for="email" 
            class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Email
            </label> -->
            <input
            :disabled="loading"
            class="w-full rounded-lg border border-zinc-950/10 px-3.5 py-2.5 text-zinc-950 sm:text-sm disabled:opacity-50"
            v-model="email"
            id="email"
            type="email"
            autocomplete="email"
            autocapitalize="off"
            placeholder="email@example.com"
            >
          </div>
          <div class="grid gap-2">
            <input 
            :disabled="loading"
            class="w-full rounded-lg border border-zinc-950/10 px-3.5 py-2.5 text-zinc-950 sm:text-sm disabled:opacity-50"
            v-model="password"
            id="password"
            type="password"
            autocapitalize="off"
            autocomplete="current-password"
            placeholder="••••••••••"
            >
          </div>
          <div v-if="formError" class="text-sm text-muted-foreground" style="color: red">
            {{ formError }}
          </div>
          <button 
          :disabled="loading"
          class="btn btn--md btn--yellow w-full disabled:opacity-50"
          type="submit"
          >
            Sign in
          </button>
          <div class="flex items-center">
            <span></span>
            <!-- <label 
            for="password" 
            class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Password
            </label> -->
            <span
            @click="forgot = true"
            class="ml-auto inline-block text-sm underline cursor-pointer"
            >
              Forgot your password?
            </span>
          </div>
        </form>

        <div v-if="forgot && !otpSent" class="grid gap-4">
          <div class="grid gap-2">
            <!-- <label 
            for="email" 
            class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Email
            </label> -->
            <input
            class="w-full rounded-lg border border-zinc-950/10 px-3.5 py-2.5 text-zinc-950 sm:text-sm"
            v-model="email"
            id="email"
            type="email"
            autocomplete="email"
            autocapitalize="off"
            placeholder="email@example.com"
            :disabled="otpSent || loading"
            >
          </div>
          <button 
          @click="getMagicLink"
          :disabled="otpSent || loading"
          class="btn btn--md btn--yellow w-full disabled:opacity-50"
          >
            Get login link
          </button>
          <div class="flex justify-between text-sm text-muted-foreground">
            <span></span>
            <span @click="forgot = false" class="cursor-pointer underline">Go back to sign in</span>
          </div>
        </div>
        <div v-if="forgot && otpSent" class="grid gap-4">
          <div class="text-sm text-muted-foreground">
            Your login link has been sent to your email. Please check your inbox and junk mail.
          </div>
        </div>
        <!-- <div class="mt-4 text-center text-sm">
          Don't have an account? 
          <NuxtLink to="/join" class="underline">Sign up</NuxtLink>
        </div> -->
      </div>
    </div>
  </div>

</template>

<script setup>
const user = useSupabaseUser()
const supabase = useSupabaseClient()
const messageStore = useMessageStore()
const userStore = useUserStore()

const route = useRoute()
const { $seo } = useNuxtApp()

const loading = ref(false)
const formError = ref(null)
const email = ref('')
const password = ref('')
const otp = ref('')

const otpSent = ref(false)

const forgot = ref(false)

const getMagicLink = async () => {
  const { data, error } = await supabase.auth.signInWithOtp({
    email: email.value,
    options: {
      shouldCreateUser: false
    }
  })
  if (!error) {
    otpSent.value = true
    messageStore.message(`Please check your email for the link to log in.`, 10, 'success')
  } else {
    messageStore.message(`There has been an error attempting to log in. Please try again later.`)
  }
}

const verifyOtp = async () => {
  const { data: otpLogin, error: otpLoginError } = await supabase.auth.verifyOtp({
    email: email.value,
    token: otp.value,
    type: 'email',
  })
  if (!otpLoginError) {
    if (otpLogin.user) {
      messageStore.message(`Welcome!`, 5, 'success')
      userStore.signIn(otpLogin.user.id)
    }
  }
}

const handleStandardSignin = async () => {
  if (!email.value || !password.value) {
    formError.value = 'Please enter your email and password'
    return
  }
  try {
    loading.value = true
    formError.value = null
    const { data: signin, error: signinError } = await supabase.auth.signInWithPassword({
      email: email.value,
      password: password.value
    })
    if (signinError) {
      if (signinError.message.includes('Invalid login')) {
        formError.value = 'Invalid login. Please try again.'
        return
      }
      formError.value = signinError
      return
    }
    if (signin.user) {
      messageStore.message(`Welcome!`, 5, 'success')
      userStore.signIn(signin.user.id)
    }
  } catch (error) {
    formError.value = error
  } finally {
    loading.value = false
  }
}

const seo = computed(() => {
  return $seo({
    meta_title: 'Log in to Drum Rudiments – Continue Your Practice', 
    meta_description: 'Welcome back! Log in to your Drum Rudiments account to continue your practice session and track your drumming progress.', 
    meta_image: null
  }, route.fullPath)
})

useHead(seo)

watchEffect(async () => {
  if (user.value) {
    reloadNuxtApp({
      path: "/play",
      ttl: 100
    })
  }
})
</script>